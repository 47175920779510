<script lang="ts">
  import { clsx } from "clsx";
  import { ArrowUpRightIcon } from "lucide-svelte";
  import { onDestroy, onMount } from "svelte";
  import type { ComponentType } from "svelte";

  import MistralLogo from "$lib/assets/logos/customers/MistralLogo.svelte";
  import RampLogo from "$lib/assets/logos/customers/RampLogo.svelte";
  import SubstackLogo from "$lib/assets/logos/customers/SubstackLogo.svelte";
  import SunoLogo from "$lib/assets/logos/customers/SunoLogo.svelte";
  import MarketingButton from "../MarketingButton.svelte";

  type Quote = {
    quote: string;
    name: string;
    title: string;
    logo: ComponentType;
    caseStudyUrl?: string;
  };

  const quotes: Quote[] = [
    {
      quote:
        "Modal Sandboxes enable us to execute generated code securely and flexibly. We expedited the development of our code interpreter feature integrated into Le Chat.",
      name: "Wendy Shang",
      title: "AI Scientist",
      logo: MistralLogo,
    },
    {
      quote:
        "Modal makes it easy to write code that runs on 100s of GPUs in parallel, transcribing podcasts in a fraction of the time.",
      name: "Mike Cohen",
      title: "Head of Data",
      logo: SubstackLogo,
      caseStudyUrl: "/blog/substack-case-study",
    },
    {
      quote:
        "Tasks that would have taken days to complete take minutes instead. We’ve saved thousands of dollars deploying LLMs on Modal.",
      name: "Rahul Sengottuvelu",
      title: "Head of Applied AI",
      logo: RampLogo,
      caseStudyUrl: "/blog/ramp-case-study",
    },
    {
      quote:
        "The beauty of Modal is that all you need to know is that you can scale your function calls in the cloud with a few lines of Python.",
      name: "Georg Kucsko",
      title: "Co-founder and CTO",
      logo: SunoLogo,
      caseStudyUrl: "/blog/suno-case-study",
    },
  ];

  let activeQuoteIndex = 0;
  let quoteHeights = quotes.map(() => 340);

  let timeout = 0;
  function rotateQuote() {
    clearTimeout(timeout);
    timeout = window.setTimeout(() => {
      activeQuoteIndex = (activeQuoteIndex + 1) % quotes.length;
      rotateQuote();
    }, 5000);
  }

  onMount(() => {
    rotateQuote();
  });

  onDestroy(() => {
    clearTimeout(timeout);
  });
</script>

<section class="my-40 marketing-container">
  <div class="px-8 lg:px-16 xl:px-28">
    <div class="flex flex-wrap gap-8 sm:gap-16 mb-20">
      {#each quotes as quote, index}
        <button
          class={clsx(
            "transition-colors",
            activeQuoteIndex === index
              ? "fill-light-green"
              : "fill-light-green/40 hover:fill-light-green",
          )}
          on:click={() => {
            activeQuoteIndex = index;
            rotateQuote();
          }}
          aria-label={`Show quote from ${quote.name}`}
        >
          <svelte:component this={quote.logo} />
        </button>
      {/each}
    </div>

    <!-- Quote container takes the same height as the maximum height of any quote. -->
    <div class="relative" style:height="{Math.max(...quoteHeights)}px">
      {#each quotes as quote, index}
        {@const active = activeQuoteIndex === index}
        <div
          class="absolute transition-opacity duration-700"
          bind:clientHeight={quoteHeights[index]}
          style:opacity={active ? "1.0" : "0.0"}
          class:pointer-events-none={!active}
        >
          <!-- Note: The bottom padding pb-4 is very important! Otherwise the descenders
            on "y" and "g" in the blockquote would get clipped off. -->
          <blockquote class="marketing-h2 pb-4" style="text-indent: -0.325em">
            “{quote.quote}”
          </blockquote>

          <p class="mt-4">
            <span
              class="inline-block w-3 h-3 rounded-full bg-brand-green mr-3"
            /><span class="font-semibold">{quote.name}</span>, {quote.title}
          </p>
        </div>
      {/each}
    </div>

    <div class="h-16" />

    {#if quotes[activeQuoteIndex].caseStudyUrl}
      <MarketingButton
        href={quotes[activeQuoteIndex].caseStudyUrl}
        variant="primary"
      >
        Case Study
        <ArrowUpRightIcon size={16} class="ml-2" />
      </MarketingButton>
    {/if}
  </div>
</section>

<style lang="postcss">
  /* Text gradient for big quote. */
  blockquote {
    /*
    Hack: The negative margin and positive padding extend the background to
    the left of the element's border, allowing us to apply the gradient coloring
    to the left quote.
    */
    margin-left: -20px;
    padding-left: 20px;

    background-clip: text;
    color: transparent;
    background-image: linear-gradient(
      83deg,
      #7fee64 10.65%,
      #6feaf8 21.07%,
      #f6fadb 34.9%,
      #7fee64 60.65%
    );
    background-size: 200%;
    animation: gradientAnimation 8s ease-in-out infinite;
  }

  @keyframes gradientAnimation {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: -400px 0;
    }
    100% {
      background-position: 0 0;
    }
  }
</style>
