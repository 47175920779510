<script lang="ts">
  export let width: number = 132;
  export let height: number = 25;
</script>

<svg {width} {height} viewBox="0 0 164 31" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_2787_3394)">
    <path
      d="M36.8747 24.9334V0.851562H30.8543V6.87203H24.8338V12.8925H18.8133V6.87203H12.7929V0.851562H6.77242V24.9334H0.751953V30.9539H18.8133V24.9334H12.7929V18.913H18.8133V24.9334H24.8338V18.913H30.8543V24.9334H24.8338V30.9539H42.8952V24.9334H36.8747Z"
    />
    <path
      d="M54.9355 24.9323V6.87109H60.3934L63.6705 19.191L66.9107 6.87109H72.3808V24.9323H68.9928V10.7149L65.4077 24.9323H61.8965L58.3238 10.7149V24.9323H54.9355Z"
    />
    <path
      d="M75.7773 10.0743V6.87109H79.2393V10.0743H75.7773ZM75.7773 24.9323V11.8485H79.2393V24.9323H75.7773Z"
    />
    <path
      d="M81.3652 21.1996L84.8395 20.6697C84.9873 21.3434 85.2869 21.8546 85.7387 22.2037C86.5608 22.9255 88.8664 22.8684 89.6566 22.2345C90.1467 21.8973 90.2846 21.0933 89.866 20.6574C89.6935 20.4933 89.3073 20.3413 88.7079 20.2014C85.9153 19.5853 84.1453 19.0229 83.3981 18.5137C81.3831 17.2402 81.3444 14.1909 83.1887 12.7109C84.6549 11.2096 89.7311 11.2249 91.3445 12.4276C92.2151 13.0108 92.8145 13.8732 93.1433 15.0148L89.8785 15.6184C89.5348 14.448 88.6979 14.0445 87.4145 14.0415C86.5028 14.0415 85.8499 14.1688 85.4558 14.4233C84.964 14.7424 84.9138 15.4068 85.4065 15.7539C85.7184 15.9839 86.7964 16.3084 88.6403 16.7272C90.484 17.146 91.7717 17.6594 92.5026 18.2671C94.1633 19.6811 93.849 22.5484 92.0836 23.9343C90.3442 25.6123 85.3417 25.6322 83.4042 24.1437C82.3652 23.4211 81.6857 22.4397 81.3652 21.1996Z"
    />
    <path
      d="M107.929 24.9325H104.467V11.8487H107.683V13.7091C108.233 12.8304 108.728 12.2512 109.167 11.9719C110.312 11.2571 111.786 11.5245 112.943 12.206L111.871 15.2242C110.307 14.1838 108.664 14.5479 108.218 16.5057C107.828 17.6342 107.944 22.9831 107.929 24.9325Z"
    />
    <path
      d="M116.821 15.8402L113.68 15.2733C114.525 12.4283 116.246 11.5752 119.347 11.5527C121.689 11.5837 123.35 11.8553 124.392 13.493C124.7 14.0556 124.854 15.0886 124.854 16.5915C124.865 18.0351 124.706 22.0005 124.983 23.1766C125.094 23.723 125.301 24.3081 125.605 24.9323H122.18C122.077 24.7047 121.838 23.8589 121.725 23.5033C120.577 24.6176 119.25 25.2272 117.672 25.2282C115.217 25.277 113.321 23.7629 113.322 21.3596C113.313 19.9235 114.047 18.6675 115.337 18.0518C115.973 17.7437 116.891 17.4749 118.09 17.2448C119.708 16.941 120.829 16.6575 121.454 16.3947V16.0498C121.454 15.3844 121.289 14.9102 120.961 14.6268C120.487 14.1411 118.339 14.0062 117.659 14.5652C117.314 14.8076 117.035 15.2326 116.821 15.8403L116.821 15.8402ZM121.454 18.649C120.823 18.9119 118.005 19.3662 117.462 19.7825C116.565 20.3721 116.597 21.5692 117.326 22.259C118.174 23.0825 119.553 22.8707 120.492 22.1725C121.638 21.352 121.424 20.1238 121.454 18.649Z"
    />
    <path d="M128.064 24.9323V6.87109H131.527V24.9323H128.064Z" />
    <path
      d="M157.99 24.9323H154.023L152.446 20.8298H145.226L143.736 24.9323H139.867L146.902 6.87109H150.758L157.99 24.9323H157.99ZM151.276 17.7867L148.787 11.0846L146.348 17.7867H151.276Z"
    />
    <path d="M159.613 24.9323V6.87109H163.26V24.9323H159.613Z" />
    <path
      d="M102.415 11.8499V14.6097H100.05V19.8825C100.05 20.9504 100.073 21.5727 100.118 21.7491C100.479 22.6972 101.461 22.3429 102.403 22.0264L102.699 24.7123C100.686 25.5636 96.9745 25.6642 96.6866 22.7536C96.6069 22.3056 96.5839 21.1765 96.5757 19.8829V14.6101H94.9863V11.8503H96.5757V9.2508L100.05 7.23047V11.8505H102.415L102.415 11.8499Z"
    />
  </g>
  <defs>
    <clipPath id="clip0_2787_3394">
      <rect
        width="162.517"
        height="30"
        transform="translate(0.769531 0.869141)"
      />
    </clipPath>
  </defs>
</svg>
